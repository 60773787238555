import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3c4c7d9cb6d87372626f695e044bd381@o4506423918854144.ingest.us.sentry.io/4507563988287488",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});